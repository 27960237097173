<template>
  <section class="positioner">
    <section
      v-if="inputsState.isVisible"
    >
      <movement-sides
        v-if="movementButtonsActive"
        :disabled="inputsState.disabled"
        @position="changeMovement"
        @end-contact="endContact"
      />
      <section
        v-if="!gameState.isLeft"
        class="flex justify-between"
      >
        <joy-stick
          v-if="showJoystick"
          :disabled="inputsState.disabled"
        />
        <section
          v-else
          class="placeholder"
        />
        <section v-show="!showJoystick" />
        <section
          class="relative bottom-right"
          :style="{transform: `scale(${scaleCoef})`}"
        >
          <action-button
            v-if="inputsState.actionVisible"
            :disabled="inputsState.actionDisabled"
          />
          <!-- <movement-buttons-component v-else /> -->
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          class="relative bottom-left"
          :style="{transform: `scale(${scaleCoef})`}"
        >
          <action-button
            v-if="inputsState.actionVisible"
            :disabled="inputsState.actionDisabled"
          />
          <!-- <movement-buttons-component v-else /> -->
        </section>
        <joy-stick
          v-if="showJoystick"
          :disabled="inputsState.isDisabled"
        />
        <section
          v-else
          class="placeholder"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import JoyStick from './JoyStick.vue'
import ActionButton from './ActionButton.vue'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import {
  MovementSides,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    JoyStick,
    ActionButton,
    MovementSides
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      gameState: 'GameSettingsState/getGameSettingsState',
      inputsState: 'InputsState/getInputsState',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      actionDisabled: 'InputsState/getIsActionDisabled'
    })
  },
  methods: {
    endContact() {

      this.$store.commit('MovementState/SET_POSITION_X', 0)

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.placeholder {
  width: 300px;
}

.buttons-position {
  position: absolute;
  top: 0;
  left: 45%;
  transform: translate(-50%);
}
.bottom-right {
  transform-origin: bottom right;
  display: flex;
  justify-content: flex-end;
}
.bottom-left {
  transform-origin: bottom left;
  display: flex;
  justify-content: flex-start;
}
</style>
