<template>
  <mobile-button
    :is-scaled="false"
    :disabled="isDisabled"
    type="start-luge"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  computed: {
    ...mapGetters({
      isStart: 'ActionButtonState/isStart',
      isJumpIn: 'ActionButtonState/getIsJumpIn',
      isDisabled: 'InputsState/getDisabled'
    })
  },
  methods: {
    performAction() {

      this.$store.commit('ActionButtonState/SET_TOUCH_START', true)

    },
    endAction() {

      this.$store.commit('ActionButtonState/SET_TOUCH_START', false)
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style>

</style>
