import store from '@/store'
// import { LoadingState } from '@powerplay/core-minigames-ui'
import {
  settings,
  SettingsTypes,
  timeManager
} from '@powerplay/core-minigames'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  private store = store

  public whatIsStore(): typeof store {

    return this.store

  }

  public getFpsStarted(): boolean {

    return this.store.getters['FpsLookerState/getFpsStarted']

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    this.store.commit('FpsLookerState/SET_FPS_STATE', fpsData)

  }

  public allowDirectionState(): void {

    this.store.commit('DirectionsState/SET_STATE', {
      show: true
    })

  }

  /*
   * public getLoadingState (): LoadingState {
   *
   *  return this.store.getters['LoadingState/getLoadingState']
   *
   * }
   */

  public setLoadingState(loadingState: unknown): void {

    this.store.commit('LoadingState/SET_STATE', loadingState)

  }

  public setBeforeGameStartPhase(): void {

    this.store.commit('GameSettingsState/SET_STATE', {
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1
    })

  }

  public setUpdateTimeState(): void {

    store.commit('TimeState/SET_STATE', {
      timeWithPenalty: timeManager.getGameTimeWithPenaltyInFormat(3),
      time: timeManager.getGameTimeWithPenaltyInSeconds(undefined, undefined, 3),
      penaltySeconds: timeManager.getPenaltyInfo().seconds
    })

  }

  public hideSpeedVisibility(): void {

    this.store.commit('SpeedmeterState/SET_VISIBILITY', false)

  }

}

export const stateManager = new StateManager()
