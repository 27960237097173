import {
  game,
  playersManager,
  THREE
} from '@powerplay/core-minigames'
import {
  idealLineConfig,
  hillLineDebugConfig
} from '../config'
import {
  IdealLineColors,
  type IntersectionInfo
} from '../types'
import { meshGeometryHelper } from './MeshGeometryHelper'

/**
 * Trieda pre helper pre idealnu liniu
 */
export class IdealLineHelper {

  /** Multimesh pre sipky */
  private multiMesh: THREE.Mesh

  /** Pomocny vektor */
  private helpVector = new THREE.Vector3()

  /** Geometria originalneho meshu */
  private originalMeshGeometry: THREE.BufferGeometry

  /** Posledna nastavena farba */
  private lastColor?: IdealLineColors

  /** Aktualne hodnoty efektivity - iba na aktualnu zakrutu */
  private curveActualEfficiencies: number[] = []

  /** Celkove hodnoty efektivity - celkove na ceku trat */
  private curveTotalEfficiencies: number[] = []

  /** Celkova efektivita idealnej linie */
  private idealLineTotalEfficiency?: number

  /**
   * Konstruktor
   * @param name - Meno meshu, z ktoreho naklonujeme multimesh
   */
  public constructor(name = 'IdealTrack_Indicator2') {

    const originalMesh = game.getMesh(name)
    this.multiMesh = originalMesh.clone()
    this.originalMeshGeometry = originalMesh.geometry.clone()
    originalMesh.visible = false
    game.scene.add(this.multiMesh)
    this.setColor(IdealLineColors.green)
    this.multiMesh.visible = false
    this.multiMesh.frustumCulled = false
    this.multiMesh.renderOrder = 4

  }

  /**
   * Change visibility
   * @param visibility - viditelnost
   */
  public changeVisibility(visibility: boolean): void {

    this.multiMesh.visible = visibility

  }

  /**
   * Vypocitanie aktualnej efektivity idealnej linie + porobenie veci okolo toho
   * @param offsetFromIdeal - Offset od idealu
   */
  public manageActualEfficiency(offsetFromIdeal: number): void {

    const efficiency = this.getActualEfficiency(offsetFromIdeal)
    this.setIdealLineEfficiency(efficiency)
    this.setColorFromEfficiency(efficiency)

  }

  /**
   * Vypocitanie efektivity idealnej linie v aktualnom frame
   * @param offset - Aktualny offset
   * @returns Efektivita
   */
  private getActualEfficiency(offset: number): number {

    const {
      maxOffset, idealLineToleranceMin, coefAdd, coefMultiply
    } = idealLineConfig.efficiency
    const strength = playersManager.getPlayer().attribute.total
    let idealLineTolerance = idealLineToleranceMin + ((coefAdd - strength) * coefMultiply)
    if (idealLineTolerance < idealLineToleranceMin) idealLineTolerance = idealLineToleranceMin
    let absOffset = Math.abs(offset) - idealLineTolerance
    if (absOffset < 0) absOffset = 0
    if (absOffset > maxOffset) absOffset = maxOffset

    return (1 - (absOffset / maxOffset))

  }

  /**
   * Nastavenie efektivity idealnej linie v jednom frame
   * @param offset - Efektivita
   */
  private setIdealLineEfficiency(efficiency: number): void {

    this.curveActualEfficiencies.push(efficiency)
    this.curveTotalEfficiencies.push(efficiency)

  }

  /**
   * Vypocitanie a vratenie aktualneho priemeru efektivity idealnej linie
   * @returns Priemerna efektivita
   */
  public getActualAverageEfficiency(): number {

    const sum = this.curveActualEfficiencies.reduce((a, b) => a + b, 0)
    const average = (sum / this.curveActualEfficiencies.length) || 0

    this.curveActualEfficiencies = []

    return average

  }

  /**
   * Vypocitanie a vratenie celkvoeho priemeru efektivity idealnej linie
   * @param cacheValue - Ci sa ma cacheovat hodnota alebo nie
   * @returns Celkova efektivita
   */
  public getTotalAverageEfficiency(cacheValue = true): number {

    if (this.idealLineTotalEfficiency !== undefined) return this.idealLineTotalEfficiency

    const sum = this.curveTotalEfficiencies.reduce((a, b) => a + b, 0)
    const efficiency = (sum / this.curveTotalEfficiencies.length) || 0

    if (cacheValue) this.idealLineTotalEfficiency = efficiency

    return efficiency

  }

  /**
   * Aktualizovanie pozicii
   * @param vertices - Vrcholy
   * @param normals - Normaly
   */
  private updatePositions(vertices: THREE.Vector3[], normals: THREE.Vector3[]): void {

    meshGeometryHelper.changeGeometryData(
      this.multiMesh,
      this.originalMeshGeometry,
      vertices,
      normals
    )

  }

  /**
   * Nastavenie farby
   * @param color - Farba
   */
  public setColor(color: IdealLineColors): void {

    if (color === this.lastColor) return

    this.lastColor = color

    const material = this.multiMesh.material as THREE.MeshBasicMaterial
    material.color.set(idealLineConfig.colors[color])

  }

  /**
   * Nastavenie farby podla efektivity
   * @param efficiency - Efektivita
   */
  public setColorFromEfficiency(efficiency: number): void {

    let color = IdealLineColors.red
    if (efficiency >= 0.7) color = IdealLineColors.yellow
    if (efficiency >= 0.9) color = IdealLineColors.green

    this.setColor(color)

  }

  /**
   * ziskame vrcholy stvorca
   * @param point - stredovy bod
   * @param dirVec - smerovy vektor
   * @param normVec - normalovy vektor
   * @param squareWidth - sirka stvorca
   * @returns - pole verticlov stvorca
   */
  private getSquareVerticles(
    point: THREE.Vector3,
    dirVec: THREE.Vector3,
    normVec: THREE.Vector3,
    squareWidth: number
  ): THREE.Vector3[] {

    const area = squareWidth * squareWidth
    const r = Math.sqrt(area / 2)

    // najdeme si pomocne vektory
    const v = this.helpVector.clone().crossVectors(dirVec, normVec)
    const u = this.helpVector.clone().crossVectors(v, normVec)

    // prenasobime vektory polomerom
    const ru = u.clone().multiplyScalar(r)
    const rv = v.clone().multiplyScalar(r)

    // vyratame body na krajoch stvorca
    const p = point.clone().add(ru)
    const p1 = point.clone().add(rv)
    const p2 = point.clone().sub(ru)
    const p3 = point.clone().sub(rv)

    // najdeme vrcholy
    const q = point.clone().add(p.clone().sub(p1.clone()))
    const q1 = point.clone().add(p1.clone().sub(p2.clone()))
    const q2 = point.clone().add(p2.clone().sub(p3.clone()))
    const q3 = point.clone().add(p3.clone().sub(p.clone()))

    return [q3, q2, q, q1]

  }

  /**
   * Vykreslime idealne pozicie
   * @param positions - intersekty na ktorych chceme mat sipky
   * @param arrowWidth - ako velke sipky chceme
   */
  public drawIdealLineArrows(positions: IntersectionInfo[], arrowWidth = 0.4): void {

    const verticles: THREE.Vector3[] = []
    const normals: THREE.Vector3[] = []
    let dirVec = this.helpVector.clone()

    for (let i = 0; i < positions.length; i += 1) {

      if (hillLineDebugConfig.showIdealPathPoints) {

        game.scene.add(new THREE.ArrowHelper(positions[i].normal.clone(), positions[i].point.clone(), 100, 0x65764D))

      }

      const currentPos = positions[i].point
      if (positions[i + 1]) dirVec = this.getSimpleDirVec(currentPos, positions[i + 1].point)

      verticles.push(...this.getSquareVerticles(
        currentPos,
        dirVec,
        positions[i].normal,
        arrowWidth
      ))

      normals.push(positions[i].normal)

    }

    this.updatePositions(verticles, normals)

  }

  /**
   * ziskame smerovy vektor
   * @param pointFrom - odkial
   * @param pointTo - kam
   * @returns normalizovany smerovy vektor
   */
  private getSimpleDirVec(pointFrom: THREE.Vector3, pointTo: THREE.Vector3): THREE.Vector3 {

    return pointTo.clone().sub(pointFrom).normalize()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.lastColor = undefined
    this.curveActualEfficiencies = []
    this.curveTotalEfficiencies = []
    this.idealLineTotalEfficiency = undefined

  }

}
