/** Konfig pre nastavenie rychlosti hraca */
export const speedConfig = {

  /** Zakladna startovacia rychlost v m/s */
  startSpeedBase: 2,

  /** Koeficient pre startovaciu rychlost */
  startSpeedCoef: 0.5,

  /** Akceleracne koeficienty */
  acceleration: {

    /** Koeficienty pre silu do 1000 */
    strengthLowBase: 0.12,
    strengthLowCoef: 0.05,

    /** Koeficienty pre silu nad 1000 */
    strengthHighBase: 0.17,
    strengthHighCoef: 0.03

  },

  /** Koeficient spomalenia po naraze */
  slowDownCoefAfterImpact: 0.99,

  /** Zakladna forward force, ktorou zrychlujeme boby */
  forwardForceBase: 0.05,

  /** Koeficient pre forward force */
  forwardForceCoef: 0.01,

  /** Znasobovac povodnych sil pri starte */
  startInputCoef: 0.001,

  /** Backward force koeficient na spomalenie pri natoceni sani */
  backwardForceCoef: -0.05,

  /** Po kolkych frameoch prestane posobit forceForward */
  framesAfterStartToDisableForceForward: 30

}
