import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface PushBarState {
  visible: boolean,
  barValue: number,
  mark: number,
  showMark: boolean,
  pushes: number[]
}

const initialState = () => ({
  visible: false,
  barValue: 0,
  mark: 0,
  showMark: false,
  pushes: []
})

const pushBarState: Module<PushBarState, RootState> = {
  state: initialState(),
  getters: {
    getPushBarState: (moduleState: PushBarState) => moduleState,
    getPushBarVisible: (moduleState: PushBarState) => moduleState.visible,
  },
  mutations: {
    RESET: (moduleState: PushBarState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PushBarState, newState: PushBarState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.barValue !== undefined) moduleState.barValue = newState.barValue
      if (newState.mark !== undefined) moduleState.mark = newState.mark
      if (newState.showMark !== undefined) moduleState.showMark = newState.showMark
      if (newState.pushes !== undefined) moduleState.pushes = newState.pushes

    },
    SET_IS_VISIBLE: (moduleState: PushBarState, visible: boolean) => {

      moduleState.visible = visible

    }
  },
  namespaced: true
}

export default pushBarState
