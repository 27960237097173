<template>
  <game-luge-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameLugeComponent from './components/GameLugeComponent.vue'

export default defineComponent({
  components: {
    GameLugeComponent
  },
})

</script>
