import {
  gameConfig,
  startUpPhaseConfig
} from '@/app/config'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import { playerMovementManager } from '@/app/entities/player/PlayerMovementManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { DrivePhaseManager } from '@/app/phases/DrivePhase/DrivePhase'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import { pushbarManager } from '@/app/PushbarManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  gameStats,
  requestManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import { inputsManager } from '../../InputsManager'
import {
  DisciplinePhases,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** blokovanie jumpInu po anne */
  public blockedJumpIn = false

  /**
   * aktivny event
   */
  private activeEventType = TutorialEventType.awaitingEvent

  /** Ci je aktivny event */
  public isEventActive = true

  /** CI sa trigerol push */
  private noPushTriggered = false

  /** semafor kolizie */
  public firstSideCollisionEnded = false

  /** semafor prvej zakruty */
  private firstCurveEnded = false

  /** Ci bol reset uz */
  public isResetedOnce = false

  /** Aby neboli dva frames */
  private isPerfectAlready = false

  /** zobrazovanie rychlosti v ui */
  private showSpeed = false

  private finished = false

  /** nastavenie objectives */
  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.startUp as string,
        passed: false,
        failed: false,
        name: TutorialObjectiveIds.startUp as string
      },
      {
        id: TutorialObjectiveIds.runUp as string,
        passed: false,
        failed: false,
        name: TutorialObjectiveIds.runUp as string
      },
      {
        id: TutorialObjectiveIds.speed as string,
        passed: false,
        failed: false,
        name:
                    `${tutorialUIChange.vm.$t('tutorialTask7-3')} ${gameConfig.tutorialMaxSpeed}` +
                    'km/h'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('BlurState/SET_IS_ACTIVE', false)
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          store.commit('TutorialState/SET_SHOW_PUSH_BAR', true)
          store.commit('TutorialState/SET_BUTTONS', {
            showAction: true,
            showMovement: false
          })

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_SHOW_PUSH_BAR', false)
          store.commit('TutorialState/SET_BUTTONS', {
            showAction: false,
            showMovement: false
          })

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEnd,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          if (game.paused) game.resumeGame()
          tutorialUIChange.setMessage(false, '')
          tutorialUIChange.setAnne(false)

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return
    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    store.commit('SpeedmeterState/SET_STATE', {
      visible: this.showSpeed,
      speed: speedManager.getActualSpeed().toFixed(2)
    })
    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /** ci bola kolizia s mantinelom */
  public getWasCollision(): boolean {

    return this.firstSideCollisionEnded

  }

  /** sledovanie inputov */
  public checkInput(): void {

    if (this.blockedJumpIn && !inputsManager.actionPressed) {

      this.blockedJumpIn = false

    }
    if (
      TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()
    ) {

      if (this.isEventActive && MobileDetector.isMobile()) {

        this.eventActionPressed()

      } else if (this.isEventActive && !MobileDetector.isMobile()) {

        this.eventActionPressed()

      }

    }

  }

  /**
   * Mechanizmy eventov
   * @param eventType - event
   * @returns
   */
  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial() || this.finished) return
    this.activeEventType = eventType
    const mobile = MobileDetector.isMobile()

    if (eventType === TutorialEventType.afterStart) {

      if (this.isResetedOnce) return
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-3', undefined, undefined, true)
      let isLeft = store.getters['GameSettingsState/isLeft']
      if (!mobile) isLeft = false

      tutorialUIChange.setAnne(true, isLeft)

    }

    if (eventType === TutorialEventType.startFailed) {

      if (this.isResetedOnce) {

        this.activeEventType = TutorialEventType.awaitingEvent
        store.commit('TutorialState/SET_BUTTONS', {
          showAction: false,
          showMovement: false
        })
        return

      }

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-4')

      let isLeft = store.getters['GameSettingsState/isLeft']
      if (!mobile) isLeft = false

      tutorialUIChange.setAnne(true, isLeft)
      store.commit('TutorialState/SET_BUTTONS', {
        showAction: false,
        showMovement: false
      })

    }

    if (eventType === TutorialEventType.firstCurve && !this.firstCurveEnded) {

      playerMovementManager.changeMoveLeftRightActive(true)
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-5')
      tutorialUIChange.setAnne(MobileDetector.isMobile() ? false : true)
      this.firstCurveEnded = true
      store.commit('TutorialState/SET_BUTTONS', {
        showAction: false,
        showMovement: true
      })

      const drivePhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.drive) as DrivePhaseManager

      drivePhase.showMobileButtons()
      this.showSpeed = true

    }
    if (eventType === TutorialEventType.sideCollision && !this.firstSideCollisionEnded) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-6')
      tutorialUIChange.setAnne(true)
      this.firstSideCollisionEnded = true

    }
    if (eventType === TutorialEventType.finish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-7', TutorialMessageColors.red)
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.complete) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-9', TutorialMessageColors.green)
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.failedComplete) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText7-8', TutorialMessageColors.red)
      tutorialUIChange.setAnne(true)

    }

    if (eventType === TutorialEventType.sideCollision && this.firstSideCollisionEnded) {

      this.activeEventType = TutorialEventType.awaitingEvent

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  /** po inpute na triggernutom evente */
  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.noPush) {

      store.commit('TutorialState/SET_BUTTONS', {
        showAction: false,
        showMovement: false
      })

      const startUpPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
      const coef = startUpPhaseConfig.tutorialSlowDownCoefFaster
      startUpPhase.enableSlowDownInTutorial(coef)

    }

    if (this.activeEventType === TutorialEventType.afterStart && !this.isResetedOnce) {

      playerAnimationManager.preStart2Animation()

    }

    if (this.activeEventType === TutorialEventType.startFailed) {

      this.isResetedOnce = true
      store.commit('TutorialState/SET_BUTTONS', {
        showAction: false,
        showMovement: false
      })
      disciplinePhasesManager.resetAttempt()

      this.noPushTriggered = false

    }

    if (this.activeEventType === TutorialEventType.awaitingEvent) {

      return

    }

    if (!this.isResetedOnce) {

      pushbarManager.blockedInput = true

    }

    if (
      [
        TutorialEventType.complete,
        TutorialEventType.failedComplete,
        TutorialEventType.finish
      ].includes(this.activeEventType)
    ) {

      this.finished = true
      if (gameStats.wasExitedGame()) {

        requestManager.redirect()
        return

      }

      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      store.commit('LoadingCircleState/SET_STATE', {
        isActive: true
      })
      tutorialManager.nextSection()

    } else {

      store.commit('TutorialState/SET_BUTTONS', {
        showAction: false,
        showMovement: false
      })

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * reset
   */
  public reset(): void {

    if (MobileDetector.isMobile()) {

      tutorialManager.setActualSectionId(0)
      return

    }
    tutorialManager.setActualSectionId(1)

  }

}

export const tutorialFlow = new TutorialFlow()
