/** Typy triggerov */
export enum TriggersTypes {

  sectorLeftToRightStart = 'sectorLeftToRightStart',
  sectorLeftToRightEnd = 'sectorLeftToRightEnd',
  sectorRightToLeftStart = 'sectorRightToLeftStart',
  sectorRightToLeftEnd = 'sectorRightToLeftEnd',
  sectorLeftToRightStartFullTurn = 'sectorLeftToRightStartFullTurn',
  sectorLeftToRightEndFullTurn = 'sectorLeftToRightEndFullTurn',
  sectorRightToLeftStartFullTurn = 'sectorRightToLeftStartFullTurn',
  sectorRightToLeftEndFullTurn = 'sectorRightToLeftEndFullTurn',
  sectorSlightLeftToRightStart = 'sectorSlightLeftToRightStart',
  sectorSlightRightToLeftStart = 'sectorSlightRightToLeftStart',
  sectorSlightLeftToRightEnd = 'sectorSlightLeftToRightEnd',
  sectorSlightRightToLeftEnd = 'sectorSlightRightToLeftEnd',
  timer = 'timer',
  finishPhase = 'endPhase',
  beforeFinish = 'beforeFinish',
  beforeSplitTime = 'beforeSplitTime',
  splitTime = 'splitTime',
  afterLastTurn = 'afterLastTurn',
  bellsStart = 'bellsStart',
  bellsEnd = 'bellsEnd',
  nextTrackPart = 'nextTrackPart'

}

/** Data o triggeri */
export interface TriggerData {

  type: TriggersTypes;
  points: number[]

}

/** Typy triggerov, ktore oznacuju sektor */
export const sectorTriggersTypes = [
  TriggersTypes.sectorLeftToRightEnd, TriggersTypes.sectorLeftToRightStart,
  TriggersTypes.sectorRightToLeftEnd, TriggersTypes.sectorRightToLeftStart,
  TriggersTypes.sectorSlightLeftToRightEnd, TriggersTypes.sectorSlightLeftToRightStart
]

/** Typy triggerov, ktore oznacuju sektor */
export const sectorFullTurnTriggersTypes = [
  TriggersTypes.sectorLeftToRightEndFullTurn, TriggersTypes.sectorLeftToRightStartFullTurn,
  TriggersTypes.sectorRightToLeftEndFullTurn, TriggersTypes.sectorRightToLeftStartFullTurn
]

/** Vsetky typy zakrut */
export enum SectionTypes {
  rightToLeft = 'RightToLeft',
  leftToRight = 'LeftToRight',
  forward = 'Forward'
}

/** Podtypy v zakrutach, kde nam ide o "predlzovanie" wide percent */
export enum SectorFullTurnTypes {

  minimumOnStart = 'minimumOnStart',
  fullOnMiddle = 'fullOnMiddle',
  minimumOnEnd = 'minimumOnEnd'

}
