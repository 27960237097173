import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre fazu ciela
 */
export const finishPhaseConfig = {

  /** delay vo framoch pred prepnutim kameru na outro; min 1 */
  cameraOutroDelay: 30,

  /** ako dlho sa boby zdrzia na targetPosition v sekundach */
  finishPhaseTweenDuration: 2,

  /** pozicia na krivke kam sa chceme dostat vo faze */
  targetPosition: 0.985,

  /** nastavenie spomalovania */
  slowdown: {

    /** pociatocna rychlost na krivke. Hodnota undefined necha rychlost z drive fazy */
    startSpeed: undefined,

    /** cim nasobime rychlost */
    coef: 0.85,

    /** minimalna rychlost */
    minSpeed: 5,

    /** ako casto menime rychlost */
    frameFreq: 5

  },

  /*
   * nastavime kameru pocas finish fazy
   * hodnota 'undefined' resetuje hodnotu na default
   */
  cameraSettings: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 2, -5),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 0.05,

    /** ci sa kamera hybe staticky */
    isStaticMovement: false
  }

}
