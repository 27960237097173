import store from '@/store'
import {
  audioManager,
  fpsManager,
  PlayerSex,
  playersManager
} from '@powerplay/core-minigames'
import {
  movementConfig,
  speedConfig
} from '../config'
import {
  type SpeedDecreaseOptions,
  AudioGroups,
  AudioNames
} from '../types'

/**
 * Trieda rychlosti
 */
export class SpeedManager {

  /** Ak nie je rychlost aktivna, tak sa vracia 0 */
  private active = false

  /** aktualna rychlost v m/s */
  private actualSpeed = 0

  /** Pociatocna rychlost vypocitana podla skillov */
  private startSpeed = 0

  /** ci je zapnute aplikovanie force forward */
  private forceForwardEnabled = true

  /** ci je zapnute aplikovanie force backward */
  private forceBackwardEnabled = true

  /** ci je zapnuta akceleracia */
  private isAccelEnabled = true

  /** Forward force */
  private forwardForce = 1

  /** Backward force na znizenie rychlosti */
  private backwardForce = 0

  /** Akceleracny koeficient vypocitany podla skillov */
  private accelerationCoef = 0

  /** hrube znizovanie rychlosti */
  private speedDecrease = {
    enabled: false,
    coef: 0,
    minSpeed: 0,
    frameFreq: 0
  } as SpeedDecreaseOptions

  /** kolko krat sme spustili getActualCalculatedSpeed */
  private frames = 0

  /** maximalna dosiahnuta rychlost */
  private topSpeed = 0

  /** Koeficient pre spomalenie */
  private slowDownCoef = 1

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /**
   * Nastavenie ci ma byt aktivna rychlost alebo nie
   * @param active - True, ak ma byt aktivna
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Nastavime ci mame zapnutu akceleraciu (vplyv spadu, force forward a force backward)
   * @param enabled - ci mame zapnutu akceleraciu
   */
  public setAccelerationEnabled(enabled: boolean): void {

    this.isAccelEnabled = enabled

  }

  /**
   * Nastavime znizovanie rychlosti
   * @param enabled - ci je zapnute
   * @param coef - koeficient
   * @param minSpeed - min rychlost
   * @param frameFreq - ako casto
   */
  public setSpeedDecrease(enabled: boolean, coef = 0, minSpeed = 0, frameFreq = 0): void {

    this.speedDecrease = {
      enabled: enabled,
      coef: coef,
      minSpeed: minSpeed,
      frameFreq: frameFreq
    } as SpeedDecreaseOptions

  }

  /**
   * Nastavenie koeficientu pre spomalenie
   * @param coef - Novy koeficient pre spomalenie
   */
  public setSlowDownCoef(coef: number): void {

    this.slowDownCoef = coef

  }

  /**
   * Nastavenie aktivnosti force forward
   * @param enabled - True ak je aktivne
   */
  public setEnabledForceForward(enabled: boolean): void {

    this.forceForwardEnabled = enabled

  }

  /**
   * Nastavenie aktivnosti force backward
   * @param enabled - True ak je aktivne
   */
  public setEnabledForceBackward(enabled: boolean): void {

    this.forceBackwardEnabled = enabled

  }

  /**
   * Vypocitanie akceleracneho koeficientu podla skillov
   * @param strength - Skill hraca
   */
  private setAccelerationCoefFromAttributes(strength: number): void {

    const {
      strengthHighBase, strengthHighCoef, strengthLowBase, strengthLowCoef
    } = speedConfig.acceleration

    if (strength < 1000) {

      this.accelerationCoef = strengthLowBase + ((strength / 1000) * strengthLowCoef)

    } else {

      this.accelerationCoef = (((strength - 1000) / 1000) * strengthHighCoef) +
                strengthHighBase

    }

    console.log(`Akceleracny koeficient je ${this.accelerationCoef}`)

  }

  /**
   * Nastavenie forwardForce podla skillov
   * @param strength - Skill hraca
   */
  private setForwardForceFromAttributes(strength: number): void {

    const { forwardForceBase, forwardForceCoef } = speedConfig

    this.forwardForce = forwardForceBase + ((strength / 2000) * forwardForceCoef)

    console.log(`Forward force start podla skillov je ${this.forwardForce} (bez nasobica)`)

  }

  /**
   * Nastavenie forward force start podla uspesnosti kliknutia
   * @param clickedPower - Uspesnost kliknutia
   */
  public changeForwardForce(clickedPower: number): void {

    const { startInputCoef } = speedConfig
    this.forwardForce *= (1 + (clickedPower * startInputCoef))
    console.log('new force forward je ', this.forwardForce)

  }

  /**
   * Zistenie forward force
   * @returns Forward force
   */
  private getForceForward(): number {

    if (!this.forceForwardEnabled) return 0

    return this.forwardForce

  }

  /**
   * Zistenie backward force
   * @returns Backward force
   */
  private getForceBackward(): number {

    return this.forceBackwardEnabled ? this.backwardForce : 0

  }

  /**
   * Nastavenie backward force
   * @param frontOffset - Offset predku a stredu sani, uz ako absolutna hodnota, tj vzdy kladne
   */
  public setForceBackward(frontOffset: number): void {

    // ak je offset dostatocne maly, tak backward force nie je
    if (frontOffset <= movementConfig.maxOffsetForFrontActualPercent) {

      this.backwardForce = 0
      return

    }

    const forceTemp = frontOffset - movementConfig.maxOffsetForFrontActualPercent
    this.backwardForce = forceTemp * speedConfig.backwardForceCoef
    console.log('backward force -> ', this.backwardForce)

  }

  /**
   * Nastavenie zakladnych koeficientov podla skillov
   */
  public setStartCoefsFromAttributes(): void {

    const strength = playersManager.getPlayer().attribute.total

    this.setForwardForceFromAttributes(strength)
    this.setAccelerationCoefFromAttributes(strength)
    this.setStartSpeedFromAttributes(strength)

    this.actualSpeed = this.startSpeed

  }

  /**
   * Vratenie aktualnej rychlosti (resp poslednej vypocitanej)
   * @returns Aktualna rychlost
   */
  public getActualSpeed(): number {

    return this.actualSpeed * this.slowDownCoef

  }

  /**
   * nastavime rychlost
   * @param speed - rychlost v m/s
   */
  public setActualSpeed(speed: number): void {

    this.actualSpeed = speed

  }

  /**
   * Znizenie rychlsoti v cieli
   */
  private decreaseSpeedInFinish(): void {

    if (!(this.frames % this.speedDecrease.frameFreq)) {

      this.actualSpeed *= this.speedDecrease.coef

      if (this.actualSpeed < this.speedDecrease.minSpeed) {

        this.actualSpeed = this.speedDecrease.minSpeed

      }

    }

  }

  /**
   * Skontrolovanie minimalnej rychlosti
   */
  private checkMinSpeed(): void {

    if (this.topSpeed < this.actualSpeed) this.topSpeed = this.actualSpeed

    const minSpeed = this.topSpeed - movementConfig.minSpeedCoef

    if (this.actualSpeed < minSpeed) this.actualSpeed = minSpeed

  }

  /**
   * Vratenie aktualnej vypocitanej rychlosti (z premennej v m/s) v m/frame
   * @param slope - uhol so zemou
   * @param offsetFromIdeal - Vzdialenost od idealnej stopy
   */
  public getActualCalculatedSpeed(slope: number, offsetFromIdeal: number): number {

    if (!this.active) return 0

    this.frames += 1

    const accelerationCurve = 1 - (Math.abs(offsetFromIdeal) * movementConfig.lineCoef)
    const acceleration = slope * this.accelerationCoef * accelerationCurve *
            Number(this.isAccelEnabled)

    this.actualSpeed += (acceleration + this.getForceForward() + this.getForceBackward())

    // znizovanie rychlosti pre final fazu
    if (this.speedDecrease.enabled) {

      this.decreaseSpeedInFinish()

    } else {

      this.checkMinSpeed()

    }

    store.commit('MainState/SET_STATE', {
      speed: this.actualSpeed,
      offsetFromIdeal
    })

    // urobime este prevodnik z m/s na m/f
    return this.actualSpeed / fpsManager.fpsLimit * this.slowDownCoef

  }

  /**
   * Spomalenie rychlosti po narazeni
   */
  public slowDownAfterImpact(): void {

    this.actualSpeed *= speedConfig.slowDownCoefAfterImpact
    this.playImpactAudio()

  }

  /**
   * zahrame audio pri kontakte s mantinelom
   */
  private playImpactAudio(): void {

    audioManager.play(AudioNames.boarding)

    const audioHitVoice = playersManager.getPlayer().sex === PlayerSex.male ?
      AudioNames.hitVoiceMale :
      AudioNames.hitVoiceFemale
    audioManager.play(audioHitVoice)

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return

    audioManager.play(
      AudioNames.commentContactWithTheBoards,
      undefined,
      undefined,
      undefined,
      true
    )

  }

  /**
   * Nastavenie startovacej rychlosti
   * @param strength - Skill hraca
   */
  private setStartSpeedFromAttributes(strength: number): void {

    const { startSpeedBase, startSpeedCoef } = speedConfig

    this.startSpeed = startSpeedBase + ((strength / 2000) * startSpeedCoef)

    console.log(`startovacia rychlost je ${this.startSpeed}m/s`)

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.active = false
    this.startSpeed = 0
    this.actualSpeed = this.startSpeed
    this.forceForwardEnabled = true
    this.forceBackwardEnabled = true
    this.isAccelEnabled = true
    this.accelerationCoef = 0
    this.speedDecrease = {
      enabled: false,
      coef: 0,
      minSpeed: 0,
      frameFreq: 0
    } as SpeedDecreaseOptions
    this.frames = 0
    this.topSpeed = 0
    this.slowDownCoef = 1
    this.setStartCoefsFromAttributes()

  }

}

export const speedManager = new SpeedManager()
