import { LugeAnimationsNames } from '@/app/types'
import { CallbackAnimationTypes } from '@powerplay/core-minigames'
import { player } from '.'

/**
 * manazer animacii pre hraca. Osekany oproti biatlonu lebo zatial nedava zmysel napajat ho na
 * velocitu managera.
 */
export class LugeAnimationManager {

  /** Counter pre brzdiacu animaciu */
  private brakeLoopCounter = 0

  /**
   * zapneme animaciu pre start
   */
  public preStartAnimation(): void {

    player.lugeAnimationsManager.changeToPaused(LugeAnimationsNames.prestart)

  }

  /**
   * zapneme animaciu pre start2
   */
  public preStart2Animation(): void {

    player.lugeAnimationsManager.addAnimationCallback(
      LugeAnimationsNames.prestart2,
      CallbackAnimationTypes.end,
      () => {

        player.lugeAnimationsManager.removeAnimationCallback(
          LugeAnimationsNames.prestart2,
          CallbackAnimationTypes.end
        )

      }
    )

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.prestart2)

  }

  /**
   * zapneme animaciu pre start2
   */
  public startAnimation(): void {

    player.lugeAnimationsManager.changeToPaused(LugeAnimationsNames.idle)

  }

  /**
   * zapneme forward animaciu
   */
  public forwardAnimation(): void {

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.idle)

  }

  /**
   * zapneme end animaciu
   */
  public endAnimation(): void {

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.finish)

  }

  /**
   * zapneme end loop animaciu
   */
  public endLoopAnimation(): void {

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.finishLoop)

  }

  /**
   * zapneme animacie start a run
   */
  public setRunAnimation(): void {

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.idle)

  }

  /**
   * zahrame animaciu emocie
   */
  public emotionAnimation(): void {

    player.lugeAnimationsManager.changeTo(LugeAnimationsNames.emotion)

  }

}

export const lugeAnimationManager = new LugeAnimationManager()
