import { player } from '@/app/entities/player'
import store from '@/store'
import {
  trainingManager,
  modes,
  gsap
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Efektivita startu */
  private startUpEfficiency = 0

  /** Efektivita naskocenia */
  private runUpEfficiency = 0

  /** tween na zobrazenie efficiency % v zakrute */
  private speedmeterTween!: gsap.core.Tween

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 4

  /** Efektivita cleanRide */
  private cleanRideValue = 100

  /** penalizacia za nedokoncenu trat */
  private penalisationCleanRide = 0

  /** ak bol posledny impact double - true */
  private wasLastImpactDouble = false

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Nastavenie textov pre shiny texty v ramci tg
   */
  public setShinyTexts(): void {

    if (!modes.isTrainingMode()) return

    const shinyTexts = []
    shinyTexts.push({
      name: 'impact-minus-5',
      active: false
    })
    store.commit('ShinyTextsState/SET_STATE', { texts: shinyTexts })

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.startUp) this.countStartUpEfficiency(valueToCount)
    if (task === Tasks.runUp) this.countRunUpEfficiency(valueToCount)

  }

  /**
   * Vyhodnotenie nejakych veci po prejdeni zakruty
   */
  public onCurvePassed(): void {

    if (!modes.isTrainingMode()) return

    this.calculateEfficiencesAverageInCurve()
    this.countIdealLineEfficiency()

  }

  /**
   * Vypocitanie priemeru efektivity v zakrute
   */
  private calculateEfficiencesAverageInCurve(): void {

    const average = player.hillLinesManager.idealLineHelper.getActualAverageEfficiency()

    console.log('Average aktualnej zakruty bol ', average)

    if (this.speedmeterTween) this.speedmeterTween.kill()

    store.commit('SpeedmeterState/SET_STATE', {
      visible: true,
      curveEfficiency: average
    })
    this.speedmeterTween = gsap.to({}, {
      duration: 2,
      onComplete: () => {

        store.commit('SpeedmeterState/SET_VISIBILITY', false)

      }
    })

  }

  /**
   * Metoda na vyhodnotenie startu
   * @param valueToCount - Hodnota startu
   */
  private countStartUpEfficiency(valueToCount: number): void {

    this.startUpEfficiency = valueToCount

    console.log(valueToCount)
    this.saveTaskValue(Tasks.startUp, this.getStartUpEfficiency())

  }

  /**
   * Metoda na vyhodnotenie rozbehu
   * @param valueToCount - Hodnota rozbehu
   */
  private countRunUpEfficiency(valueToCount: number): void {

    this.runUpEfficiency = valueToCount

    console.log(valueToCount)
    this.saveTaskValue(Tasks.runUp, this.getRunUpEfficiency())

  }

  /**
   * Vyhodnotenie efektivity idealnej linie
   * @param valueToCount - Hodnota brstartuanky
   */
  private countIdealLineEfficiency(): void {

    const efficiency = player.hillLinesManager.idealLineHelper.getTotalAverageEfficiency(false)
    this.changeUI(Tasks.idealLine, efficiency)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      store.commit('TrainingState/EDIT_TASK', {
        text: `trainingTask7-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Vratenie poctu percent za minute branky
   * @returns Percenta
   */
  public getStartUpEfficiency(): number {

    return this.startUpEfficiency

  }

  /**
   * Vratenie poctu percent za prejdene checkpointy
   * @returns Percenta
   */
  public getRunUpEfficiency(): number {

    return this.runUpEfficiency

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    const efficiency = player.hillLinesManager.idealLineHelper.getTotalAverageEfficiency()
    this.saveTaskValue(Tasks.idealLine, efficiency)

    console.log('FINAL DATA SENT')

  }

  /**
   * manualne zobrazenie clean ride tasku
   */
  public countCleanRide(): void {

    this.saveTaskValue(Tasks.cleanRide, this.cleanRideValue / 100)

  }

  /**
   * zapocitanie narazu
   * @param divider - ci bol pouzity divider - bol naraz rychlo za predcahdzajucim
   */
  public countImpact(divider: boolean) {

    if (!modes.isTrainingMode()) return
    if (this.wasLastImpactDouble && divider) return

    const impactValue = 5
    const impactMessageToSilence = 5

    this.wasLastImpactDouble = divider

    console.log(this.wasLastImpactDouble, impactValue, impactMessageToSilence)
    if (this.cleanRideValue > 0) {

      store.commit(
        'ShinyTextsState/SET_TEXT_BY_NAME',
        { name: `impact-minus-${impactMessageToSilence}`,
          active: false }
      )

      // kedze mame dve rovnake hlasky, tak musime chvilku pockat, kym zobrazime druhu
      gsap.to({}, {
        onComplete: () => {

          store.commit(
            'ShinyTextsState/SET_TEXT_BY_NAME',
            { name: `impact-minus-${impactValue}`,
              active: true }
          )

        },
        duration: 0.05
      })

      gsap.to({}, {
        onComplete: () => {

          store.commit(
            'ShinyTextsState/SET_TEXT_BY_NAME',
            { name: `impact-minus-${impactValue}`,
              active: false }
          )

        },
        duration: 1
      })

    }

    this.cleanRideValue -= impactValue
    if (this.cleanRideValue < 0) this.cleanRideValue = 0
    this.countCleanRide()

  }

  /**
   * Vyhodnotenie penalizacie pri predcasnom konci
   */
  public setPrematureEndPenalisation(): void {

    this.penalisationCleanRide = Math.round((1 - player.hillLinesManager.getActualPercent()) * 100)

    this.cleanRideValue -= this.penalisationCleanRide
    if (this.cleanRideValue < 0) this.cleanRideValue = 0

    this.countCleanRide()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.startUpEfficiency = 0
    this.runUpEfficiency = 0
    this.cleanRideValue = 100
    this.penalisationCleanRide = 0
    this.wasLastImpactDouble = false
    trainingManager.resetAll()

  }

}

export const trainingTasks = new TrainingTasks()
