import {
  PlayerSex,
  type AudioObject
} from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const spriteAudio: AudioObject[] = [
  {
    files: [
      AudioNames.spriteLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    category: AudioCategories.sprite,
    version: 3,
    sprite: {
      'audience_noise_var_02': [
        0,
        10000
      ],
      'audience_sad2': [
        11000,
        9997.00680272109
      ],
      'audience_yay3': [
        22000,
        8254.69387755102
      ],
      'ride_forward': [
        32000,
        5039.999999999999
      ],
      'ride_curves': [
        39000,
        5039.999999999999
      ]
    }
  },
  {
    files: [
      AudioNames.spriteNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    subset: true,
    randomizeSprite: true,
    category: AudioCategories.sprite,
    sprite: {
      'boarding': [
        0,
        577.4603174603175
      ],
      'boarding2': [
        2000,
        527.0521541950113
      ],
      'boarding3': [
        4000,
        674.4897959183671
      ],
      'boarding4': [
        6000,
        500.5215419501132
      ],
      'boarding5': [
        8000,
        376.87074829931964
      ],
      'boarding6': [
        10000,
        584.7619047619048
      ],
      'startHands': [
        12000,
        1197.2789115646253
      ]
    }
  },
  {
    files: [
      AudioNames.spriteVoicesFemale
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    sex: PlayerSex.female,
    randomizeSprite: true,
    subset: true,
    category: AudioCategories.sprite,
    sprite: {
      'hit_female': [
        0,
        273.0839002267574
      ],
      'hit_female2': [
        2000,
        296.7573696145127
      ],
      'hit_female3': [
        4000,
        241.11111111111148
      ],
      'hit_female4': [
        6000,
        317.32426303854845
      ],
      'hit_female5': [
        8000,
        294.85260770974975
      ],
      'hit_female6': [
        10000,
        347.1882086167799
      ]
    }
  },
  {
    files: [
      AudioNames.spriteVoicesMale
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    sex: PlayerSex.male,
    randomizeSprite: true,
    subset: true,
    category: AudioCategories.sprite,
    sprite: {
      'hit_male': [
        0,
        312.3582766439909
      ],
      'hit_male2': [
        2000,
        419.79591836734676
      ],
      'hit_male3': [
        4000,
        245.53287981859384
      ],
      'hit_male4': [
        6000,
        385.7142857142861
      ],
      'hit_male5': [
        8000,
        333.24263038548804
      ],
      'hit_male6': [
        10000,
        331.88208616780025
      ],
      'hit_male7': [
        12000,
        343.5827664399085
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'after_start_1': [
        0,
        5000
      ],
      'after_start_2': [
        6000,
        4164.85260770975
      ],
      'after_start_3': [
        12000,
        4594.784580498867
      ],
      'after_start_4': [
        18000,
        4848.231292517006
      ],
      'contact_with_the_boards': [
        24000,
        3074.943310657595
      ],
      'contact_with_the_boards2': [
        29000,
        4439.002267573698
      ],
      'finish_1': [
        35000,
        6174.580498866213
      ],
      'finish_2': [
        43000,
        7379.569160997733
      ],
      'finish_3': [
        52000,
        8431.383219954647
      ],
      'finish_4': [
        62000,
        6913.287981859412
      ],
      'split_times_minus': [
        70000,
        4687.981859410428
      ],
      'split_times_minus2': [
        76000,
        4074.716553287985
      ],
      'split_times_plus_1': [
        82000,
        3139.6598639455815
      ],
      'split_times_plus_12': [
        87000,
        4269.319727891158
      ],
      'split_times_plus_2': [
        93000,
        4000
      ],
      'split_times_plus_22': [
        98000,
        5271.133786848069
      ],
      'split_times_plus_3': [
        105000,
        3774.058956916093
      ],
      'split_times_plus_32': [
        110000,
        4500
      ]
    }
  }
]