import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import {
  LugeAnimationsNames,
  PlayerAnimationsNames
} from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {
  /*
   * prepare = 'prepare',
   * forward = 'ride',
   * left = 'ride L',
   * right = 'ride R',
   * end = 'finish',
   * endLoop = 'finish loop',
   * start = 'start',
   * prestart = 'prestart 1',
   * prestart2 = 'prestart 2',
   * lie = 'lie',
   * neutral = 'neutral',
   * happy = 'happy'
   */
  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.end]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.endLoop]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.start]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prestart]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prestart2]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.lie]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.forward]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.left]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.right]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.neutral]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  }
}

export const lugeAnimationsConfig: AnimationsSettingRepository = {
  /*
   * emotion = 'luge emotion',
   * finish = 'luge finish',
   * finishLoop = 'luge finish loop',
   * idle = 'luge idle',
   * prestart = 'luge prestart 1',
   * prestart2 = 'luge prestart 2',
   */
  [LugeAnimationsNames.emotion]: {
    loop: false,
    starting: false
  },
  [LugeAnimationsNames.finish]: {
    loop: false,
    starting: false
  },
  [LugeAnimationsNames.finishLoop]: {
    loop: true,
    starting: false
  },
  [LugeAnimationsNames.idle]: {
    loop: true,
    starting: true
  },
  [LugeAnimationsNames.prestart]: {
    loop: true,
    starting: false
  },
  [LugeAnimationsNames.prestart2]: {
    loop: false,
    starting: false
  },

}
