export const audioGameConfig = {

  /** akych komentatorov davame pri split times */
  splitTimeDifference: {

    /** plus1 */
    first: 0.1,

    /** plus2 */
    second: 0.2,

  },

  /** Konfig pre divakov a hlasitosti */
  audienceHype: {

    /** V ktorom % trate zaciname zvysovat hlasitost */
    startIncreaseVolumePercent: 0.71,

    /** V ktorom % trate prestavame zvysovat hlasitost */
    stopIncreaseVolumePercent: 0.81,

    /** Najnizsia hlasitost */
    volumeMin: 0.5,

    /** Najvyssia hlasitost */
    volumeMax: 1

  }

}
