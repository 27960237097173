import type { MaterialDataObject } from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: ['LugeMale', 'LugeFemale'],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.skierVisor]: {
    meshesArray: ['LugeMaleVisor', 'LugeFemaleVisor'],
    color: 0xFFFFFF,
    transparent: true,
    opacity: 0.2
  },
  [MaterialsNames.luge]: {
    meshesArray: ['Luge_LOWPOLY'],
    textureName: TexturesNames.luge
  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.transparent]: {
    alpha: 0.5,
    textureName: TexturesNames.transparent,
    meshesArray: [
      'Trees', 'Trees_Old', 'Audience', 'Object014'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: [
      'Ads', 'AdsStart', 'ADS_bottom_01', 'ADS_bottom_02', 'ADS_bottom_03', 'ADS_bottom_04',
      'ADS_bottom_05', 'ADS_bottom_06', 'ADS_bottom_07', 'ADS_bottom_08', 'ADS_bottom_09',
      'ADS_bottom_10', 'ADS_top_01', 'ADS_top_02', 'ADS_top_03', 'ADS_top_04', 'ADS_top_05',
      'ADS_top_06', 'ADS_top_07', 'ADS_top_08', 'ADS_top_09', 'ADS_top_10'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.transparentAds]: {
    textureName: TexturesNames.transparentAds,
    meshesArray: ['TransparentAds'],
    // lightmap: TexturesNames.lightmapHill,
    transparent: true
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['FlagsTop', 'FlagsBottom'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.iceTrack,
    meshesArray: ['TerrainFar', 'TerrainClose'],
    lightmap: TexturesNames.lightmapHill
    // vertexColors: true
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeopleStart', 'StaticPeopleFinish']
  },
  [MaterialsNames.rocks]: {
    meshesArray: ['Rocks'],
    textureName: TexturesNames.rocks,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.startline]: {
    vertexColors: true,
    meshesArray: ['Startline']
  },
  [MaterialsNames.iceTrack]: {
    meshesArray: [
      'TrackPart_01', 'TrackPart_02', 'TrackPart_03', 'TrackPart_04', 'TrackPart_05',
      'TrackPart_06', 'TrackPart_07', 'TrackPart_08', 'TrackPart_09', 'TrackPart_10'
    ],
    textureName: TexturesNames.iceTrack,
    // vertexColors: true,
    lightmap: TexturesNames.lightmapHill,
    envmap: TexturesNames.environmentReflection,
    reflectivity: 0.3
  },
  /*
   * [MaterialsNames.indicators]: {
   *     meshesArray: ['Start_Indicator'],
   *     alphamap: TexturesNames.opacityGradient,
   *     transparent: true,
   *     vertexColors: true
   * },
   */
  [MaterialsNames.idealTrackIndicator]: {
    meshesArray: ['IdealTrack_Indicator2'],
    transparent: true,
    vertexColors: true,
    textureName: TexturesNames.idealTrackIndicator
  }
}
