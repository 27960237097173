import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface ActionButtonState {
  isStart: boolean,
  touchStart: boolean,
  showJoystick: boolean,
  isJumpIn: boolean
}

const initialState = () => ({
  isStart: true,
  touchStart: false,
  showJoystick: false,
  isJumpIn: false
})

const actionButtonState: Module<ActionButtonState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getActionState: (moduleState: ActionButtonState) => moduleState,
    getStart: (moduleState: ActionButtonState) => moduleState.touchStart,
    isStart: (moduleState: ActionButtonState) => moduleState.isStart,
    getShowJoystick: (moduleState: ActionButtonState) => moduleState.showJoystick,
    getIsJumpIn: (moduleState: ActionButtonState) => moduleState.isJumpIn
  },

  mutations: {
    RESET: (moduleState: ActionButtonState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ActionButtonState, newState: ActionButtonState) => {

      moduleState.isStart = newState.isStart

    },
    SET_START_BUTTON: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.isStart = newState

    },
    SET_TOUCH_START: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.touchStart = newState

    },
    SET_SHOW_JOYSTICK: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.showJoystick = newState

    },
    SET_IS_JUMP_IN: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.isJumpIn = newState

    }
  }
}

export default actionButtonState
