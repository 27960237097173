/** Konfig pre pohyb do stran */
export const movementConfig = {

  /** Limit pre pohyb vlavo (0-1) */
  limitLeft: 0.2,

  /** Limit pre pohyb vlavo pri lavotocivej zakrute (0-1) */
  limitLeftInner: 0.1,

  /** Limit pre pohyb vpravo (0-1) */
  limitRight: 0.8,

  /** Limit pre pohyb vpravo pri pravotocivej zakrute (0-1) */
  limitRightInner: 0.9,

  /** Stred (0-1) */
  center: 0.5,

  /** Sila pre odraz od mantinela pri naraze */
  rebound: {

    /** Koeficient stepu sily */
    stepCoef: 0.002,

    /** Kolko frameov bude posobit sila */
    frames: 12,

    /** Ak je naraz na opacnu stranu po naraze, tak delime tymto cislom */
    divider: 20

  },

  /** Max prirastok pre odstredivu silu v zakrute */
  centrifugalForceMax: 0.06,

  /** Max prirastok pre odstredivu silu v zakrute */
  centrifugalForceMaxSteering: 0.01,

  /** Max prirastok pre odstredivu silu v jemnej zakrute */
  centrifugalForceMaxSlightTurn: 0.03,

  /** Max prirastok pre dostredivu silu v zakrute */
  centripetalForceMax: 0.03,

  /** Max rychlost pre odstredivu silu v m/s */
  maxSpeedCoef: 35,

  /** Min odstrediva sila */
  minCentrifugalSpeed: 5,

  /** Koeficient penalizacie za vychylenie z idealnej stopy */
  lineCoef: 0.05,

  /**
   * Hodnota v %, kolko posuvame akoby trat v maximalnej sirke zakruty, tj napr v lavotocivej
   * zakrute ideme 0 az 1.5, v pravotocivej -0.5 az 1, ak mame hodnotu 0.5
   *
   */
  offsetPercent: 0.5,

  /** Penalizacia pri vyjazde zo zakruty */
  exitTurnPenalty: {

    /** Koeficient na pocitanie sily */
    coef: 0.15,

    /** Pocet frameov, kolko bude dana sila trvat */
    frames: 15

  },

  /** koeficient minimalnej rychlosti */
  minSpeedCoef: 3,

  /** maximalna hodnota kroku zatacania na rovine */
  maxSteeringStraight: 0.04,

  /** maximalna hodnota kroku zatacania v zakrute */
  maxSteeringCurve: 0.02,

  /** pocet framov zatacania po najvyssiu hodnotu */
  steeringSpeed: 10,

  /** Rotacia kazdy input vpredu sani */
  stepFrontOnInput: 0.01,

  /** Rotacia x frameov ako rebound o tuto hodnotu vpredu sani */
  stepFrontAfterImpact: 0.015,

  /** Max rotacia medzi stredom a predkom sani */
  maxOffsetForFrontActualPercent: 0.02,

  /** Pocet frameov, ktore freezujeme nejake vypocty po naraze na tu istu stranu po sebe */
  sameSideImpactFreezeFrames: 5

}
