/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteLooped = 'looped',
  spriteNotLooped = 'notLooped',
  spriteVoicesFemale = 'voicesFemale',
  spriteVoicesMale = 'voicesMale',

  audienceHypedBells = 'audience_bellshype_var04',
  audienceHype = 'audience_hype',
  audienceNoise = 'audience_noise_var_02',
  audienceSad = 'audience_sad2',
  audienceYay = 'audience_yay3',

  boarding = 'boarding',
  boarding2 = 'boarding2',
  boarding3 = 'boarding3',
  boarding4 = 'boarding4',
  boarding5 = 'boarding5',
  boarding6 = 'boarding6',
  break = 'break',
  hitVoiceMale = 'hit_male',
  hitVoiceMale2 = 'hit_male2',
  hitVoiceMale3 = 'hit_male3',
  hitVoiceMale4 = 'hit_male4',
  hitVoiceMale5 = 'hit_male5',
  hitVoiceMale6 = 'hit_male6',
  hitVoiceMale7 = 'hit_male7',
  hitVoiceFemale = 'hit_female',
  hitVoiceFemale2 = 'hit_female2',
  hitVoiceFemale3 = 'hit_female3',
  hitVoiceFemale4 = 'hit_female4',
  hitVoiceFemale5 = 'hit_female5',
  hitVoiceFemale6 = 'hit_female6',
  ride = 'ride_forward',
  rideCurves = 'ride_curves',
  start = 'startHands',

  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentContactWithTheBoards = 'contact_with_the_boards',
  commentContactWithTheBoards2 = 'contact_with_the_boards2',
  commentFinalResults = 'final_results-new',
  commentFinalResults2 = 'final_results-new2',
  commentFinish1 = 'finish_1',
  commentFinish2 = 'finish_2',
  commentFinish3 = 'finish_3',
  commentFinish4 = 'finish_4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentAfterStart1 = 'after_start_1',
  commentAfterStart2 = 'after_start_2',
  commentAfterStart3 = 'after_start_3',
  commentAfterStart4 = 'after_start_4',
  splitTimesMinus = 'split_times_minus',
  splitTimesMinus2 = 'split_times_minus2',
  splitTimesPlus1 = 'split_times_plus_1',
  splitTimesPlus11 = 'split_times_plus_12',
  splitTimesPlus2 = 'split_times_plus_2',
  splitTimesPlus21 = 'split_times_plus_22',
  splitTimesPlus3 = 'split_times_plus_3',
  splitTimesPlus31 = 'split_times_plus_32'

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  commentators = 'commentators',
  movement = 'movement',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
