import store from '@/store'
import {
  type DisciplinePhaseManager,
  DisciplinePhases,
  Tasks,
  TutorialEventType
} from '../../types'
import {
  inputsManager,
  MobileDetector,
  modes,
  THREE
} from '@powerplay/core-minigames'
import { pushbarManager } from '@/app/PushbarManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import type { StartPhaseManager } from '../StartPhase/StartPhase'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialConfig } from '@/app/config'

/**
 * Trieda fazy pre rozbeh
 */
export class StartUpPhaseManager implements DisciplinePhaseManager {

  /** posledne miesto kde sme uspesne klikli */
  public startUpObject!: THREE.Mesh

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** pocet famov fazy */
  private frame = 0

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    this.createStartUpObject()

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('StartUp phase started')
    this.preparePhase()

  }

  /**
   * Vytvorenie objektu na idealne odstartovanie - pre debug ucely
   */
  public createStartUpObject(): void {

    /*
     * TODO: iba pre debug ucely
     * const geometry = new THREE.CircleGeometry(1)
     * const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
     * this.startUpObject = new THREE.Mesh(geometry, material)
     * game.scene.add(this.startUpObject)
     * this.startUpObject.rotation.set(Math.PI / 2 * 3, 0, 0)
     * this.startUpObject.position.set(0, 10, 0)
     */

  }

  /**
   * Zobrazenie mobilnych buttoniv
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    /*
     * store.commit('ActionButtonState/SET_SHOW_JOYSTICK', true)
     * store.commit('ActionButtonState/SET_START_BUTTON', false)
     */
    store.commit('InputsState/SET_DISABLED', false)

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frame += 1
    if (this.frame === tutorialConfig.afterStartDelay) {

      tutorialFlow.eventActionTrigger(TutorialEventType.afterStart)

    }
    if (MobileDetector.isMobile()) {

      if (
        store.getters['ActionButtonState/getStart'] &&
                !pushbarManager.blockedInput
      ) {

        console.log('mobile button pressed')

        pushbarManager.blockedInput = true
        pushbarManager.managePush()

      }

    } else {

      if (
        inputsManager.actionPressed &&
                !pushbarManager.blockedInput
      ) {

        console.log('action pressed')

        pushbarManager.blockedInput = true
        pushbarManager.managePush()

      }

    }
    if (!store.getters['ActionButtonState/getStart'] && !inputsManager.actionPressed) {

      pushbarManager.blockedInput = false

    }

    pushbarManager.update()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase = (): void => {

    console.warn('StartUp phase ended')
    if (modes.isTutorial()) {

      store.commit('InputsState/SET_VISIBLE', false)

    }

    const startPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    startPhase.disableSlowDownInTutorial()

    trainingTasks.countTaskValue(Tasks.runUp, pushbarManager.getPushQuality() / 100)
    trainingTasks.countCleanRide()

    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * reset
   */
  public reset(): void {

    // this.preparePhase()

  }

}
