/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  hill = 'hill',
  idealTrackIndicator = 'IdealTrackIndicator',
  trackLines = 'TrackLines',
  cameraBoxes = 'cameraBoxes'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  luge = 'luge',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  rocks = 'Rock',
  transparentAds = 'logoTransparent',
  timerNumbers = 'TimerNumbers',
  lights = 'Lights',
  environmentReflection = 'EnvironmentReflection',
  iceTrack = 'IceTrack',
  opacityGradient = 'OpacityGradient',
  snowParticle = 'snow',
  idealTrackIndicator = 'IdealTrackIndicator'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  skierVisor = 'skierVisor',
  luge = 'luge',
  hill = 'Atlas1',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  rocks = 'Rock',
  transparentAds = 'TransparentAds',
  timerNumbers = 'TimerNumbers',
  startline = 'startline',
  lights = 'lights',
  iceTrack = 'IceTrack',
  indicators = 'ArrowIndicator',
  idealTrackIndicator = 'IdealTrackIndicator'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  prepare = 'prepare',
  forward = 'ride',
  left = 'ride L',
  right = 'ride R',
  end = 'finish',
  endLoop = 'finish loop',
  start = 'start',
  prestart = 'prestart 1',
  prestart2 = 'prestart 2',
  lie = 'lie',
  neutral = 'neutral',
  happy = 'happy'
}

/** Mena vsetkych animacii sanok */
export enum LugeAnimationsNames {
  emotion = 'luge emotion',
  finish = 'luge finish',
  finishLoop = 'luge finish loop',
  idle = 'luge idle',
  prestart = 'luge prestart 1',
  prestart2 = 'luge prestart 2',
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}
