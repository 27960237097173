import {
  THREE,
  game,
  type CannonNamedBody
} from '@powerplay/core-minigames'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** Mesh barier na vrchu */
  public barrierTopMesh!: THREE.Mesh

  /** Fyzikalne body barier na vrchu */
  public barrierTopBodies: CannonNamedBody[] = []

  /** Mesh barier na okrajoch */
  public barrierSidesMesh!: THREE.Mesh

  /** Fyzikalne body barier na okrajoch */
  public barrierSidesBodies: CannonNamedBody[] = []

  /** Pole fyzikalnych meshov */
  public PHYSICS_MESHES = []

  /** Posledna zobrazena cast trate */
  private lastPartTrack = 2

  /** Pocet casti trate */
  private readonly TRACK_PARTS = 10

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()

    // umiestnenie startovych madiel
    const startHandlebarsMesh = game.getMesh('StartHandlebars')
    startHandlebarsMesh.position.z += 3.1
    startHandlebarsMesh.updateMatrix()

    // start indicator nepotrebujeme
    game.getMesh('Start_Indicator').visible = false

    console.log('HILL vytvoreny....')

  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    this.hillMesh = game.getMesh('_Physics_Main_Bottom')
    this.hillMesh.visible = false

  }

  /**
   * Umiestnenie startovej podlozky
   */
  public placeStartPad(): void {

    const startPad = game.getMesh('Start_Pad')
    startPad.visible = false

  }

  /**
   * nastavenie viditelnosti pre vsetky casti trate
   * @param visibility - True ak chceme aby boli viditelne
   */
  public setVisibilityOfAllTrackParts(visibility: boolean): void {

    for (let i = 1; i <= this.TRACK_PARTS; i += 1) {

      this.setVisibilityOfTrackPart(i, visibility)

    }

  }

  /**
   * Zobrazenie prvych casti trate
   */
  public showFirstTrackParts(): void {

    this.setVisibilityOfTrackPart(1, true)
    this.setVisibilityOfTrackPart(2, true)

  }

  /**
   * Nastavenie viditelnosti pre danu cast trate
   * @param index - Index casti
   * @param visibility - True, ak ma byt viditelna
   */
  private setVisibilityOfTrackPart(index: number, visibility: boolean): void {

    const partNumber = `${index < 10 ? '0' : ''}${index}`

    game.getMesh(`Roofs_${partNumber}`).visible = visibility
    game.getMesh(`ADS_top_${partNumber}`).visible = visibility
    game.getMesh(`TrackPart_${partNumber}`).visible = visibility
    game.getMesh(`ADS_bottom_${partNumber}`).visible = visibility
    game.getMesh(`TrackLight_${partNumber}`).visible = visibility


    // TODO TEMP iba docasne, aby nebol error
    console.log(index, visibility)

  }

  /**
   * Zobrazenie dalsej casti trate a schovanie predoslej
   */
  public nextTrackPart(): void {

    // schovame poslednu, ale iba ak nejde o poslednu
    if (this.lastPartTrack < this.TRACK_PARTS - 1) {

      this.setVisibilityOfTrackPart(this.lastPartTrack - 1, false)

    }

    // zobrazime nasledujucu
    this.setVisibilityOfTrackPart(++this.lastPartTrack, true)

  }

  public reset(): void {

    this.hillBodies = []
    this.barrierTopBodies = []
    this.barrierSidesBodies = []
    this.PHYSICS_MESHES = []
    this.lastPartTrack = 2
    this.createHillPhysics()

  }

}

export const hill = new Hill()
