import { THREE } from '@powerplay/core-minigames'

export const drivePhaseConfig = {

  /*
   * nastavime kameru pocas drive fazy
   * hodnota 'undefined' resetuje hodnotu na default
   */
  cameraSettings: {

    // ako daleko ma byt kamera od hraca
    idealOffset: new THREE.Vector3(0, 0.5, -0.7),

    // ideal place for camera to look at
    idealLookAt: undefined,

    // how fast should camera move (0-1)
    coefSize: undefined,

    // how fast changes should be applied (0-1)
    changeLerp: 0.05,

    // ci mame mat staticky pohyb kamery
    isStaticMovement: true

  }

}
