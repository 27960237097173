<template>
  <div
    v-show="pushBarState.visible"
    class="positioner"
  >
    <traffic-light-start-bar
      :fill-size="pushBarState.barValue"
      :mark="pushBarState.mark"
      :show-mark="pushBarState.showMark"
      hide-default-mark
      :circles="circles"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TrafficLightStartBar } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { startUpPhaseConfig } from '@/app/config'

interface PushBarStateType {
  barValue: number,
  visible: boolean,
}
interface Circle {
  color: string,
  content: string
}

export default defineComponent({
  name: 'StartEfficiencyBar',
  components: {
    TrafficLightStartBar
  },
  data() {

    return {
      timer: 0,
      circles: [] as Circle[]
    }

  },
  computed: {
    ...mapGetters({
      pushBarState: 'PushBarState/getPushBarState',
    }),
  },
  watch: {
    pushBarState: {
      immediate: true,
      deep: true,
      handler(value: PushBarStateType) {

        if (
          Math.round(value?.barValue * 2) !== this.timer
        ) {

          this.timer = value?.barValue
          this.circlesUpdate()

        }

      }
    },
    'pushBarState.visible': {
      handler(value: PushBarStateType) {

        if (value) {

          this.circlesUpdate()

        }

      }
    }
  },
  methods: {
    circleColor(value: number | undefined) {

      if (value === undefined) {

        return 'grey'

      }
      let color = 'black'
      if (value >= startUpPhaseConfig.pushBarColorValues.perfect) {

        color = 'green'

      } else if (value >= startUpPhaseConfig.pushBarColorValues.excellent) {

        color = 'yellow'

      } else if (value >= startUpPhaseConfig.pushBarColorValues.good) {

        color = 'orange'

      } else if (value > 0) {

        color = 'red'

      }
      return color

    },
    circlesUpdate() {

      this.circles = [
        {
          color: this.circleColor(this.pushBarState.pushes?.[0]),
          content: 's'
        },
        {
          color: this.circleColor(this.pushBarState.pushes?.[1]),
          content: '1'
        },
        {
          color: this.circleColor(this.pushBarState.pushes?.[2]),
          content: '2'
        },
        {
          color: this.circleColor(this.pushBarState.pushes?.[3]),
          content: '3'
        },
        {
          color: this.circleColor(this.pushBarState.pushes?.[4]),
          content: '4'
        },
      ]

    }
  }
})
</script>
<style scoped lang="less">
.positioner {
    position: absolute;
    bottom: 5%;
    left: 0;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
</style>
