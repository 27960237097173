import { TriggersTypes } from '../types'

/** Konfig pre triggery */
export const triggersConfig = {

  /** Vsetky triggery, POZOR, musia nasledovat v poradi, aby spravne fungovali */
  triggers: [
    // START
    {
      type: TriggersTypes.timer,
      points: [0.01074, 0.01074]
    },
    // SPLIT TIME
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.0151, 0.0151]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.058, 0.058]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.1345, 0.1345]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.2045, 0.2045]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.295, 0.295]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.365, 0.365]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.488, 0.488]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.558, 0.558]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.6718, 0.6718]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.7418, 0.7418]
    },
    // BELLS
    {
      type: TriggersTypes.bellsStart,
      points: [0.12, 0.12]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.2, 0.2]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.43, 0.43]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.475, 0.475]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.57, 0.57]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.65, 0.65]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.778, 0.990]
    },
    // NEXT TRACK PART
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.15, 0.15]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.24, 0.24]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.33, 0.33]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.45, 0.45]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.56, 0.56]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.66, 0.66]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.75, 0.75]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.85, 0.85]
    },
    // TURN 0 - SLIGHT LEFT TO RIGHT
    {
      type: TriggersTypes.sectorSlightLeftToRightStart,
      points: [0.058, 0.0574]
    },
    {
      type: TriggersTypes.sectorSlightLeftToRightEnd,
      points: [0.068, 0.06726]
    },
    // TURN 1 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.07072, 0.0701]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.07806, 0.0774]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.09137, 0.09165]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.09852, 0.0989]
    },
    // TURN 2 - LEFT TO RIGHT
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.124, 0.12394]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.13133, 0.131255]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.19675, 0.191245]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.2041, 0.19876]
    },
    // TURN 3 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.219595, 0.21408]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.2269, 0.22124]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.2388, 0.2342]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.247315, 0.2428]
    },
    // TURN 4 - LEFT TO RIGHT
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.2487, 0.24417]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.2559, 0.251095]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.27025, 0.26474]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.27755, 0.271805]
    },
    // TURN 5 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.31003, 0.30395]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.31972, 0.314]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.34763, 0.34463]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.35775, 0.35471]
    },
    // TURN 6 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.41969, 0.41633]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.43086, 0.4277]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.499015, 0.49895]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.510415, 0.51037]
    },
    // TURN 7 - LEFT TO RIGHT
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.5159, 0.51618]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.52605, 0.52568]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.54625, 0.54461]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.55773, 0.556005]
    },
    // TURN 8 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.57061, 0.56845]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.5832, 0.58119]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.63771, 0.639]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.651935, 0.6533]
    },
    // TURN 9 - LEFT TO RIGHT
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.68558, 0.6869]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.69855, 0.69926]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.72746, 0.72601]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.74175, 0.7399]
    },
    // TURN 10 - RIGHT TO LEFT - zakruta sa momentalne nepouziva, ale ak by trebalo, je tu
    {
      type: TriggersTypes.sectorSlightRightToLeftStart,
      points: [0.74202, 0.74]
    },
    /*
     * {
     *     type: TriggersTypes.sectorRightToLeftStartFullTurn,
     *     points: [0.75034, 0.74835]
     * },
     * {
     *     type: TriggersTypes.sectorRightToLeftEndFullTurn,
     *     points: [0.75715, 0.75546]
     * },
     */
    {
      type: TriggersTypes.sectorSlightRightToLeftEnd,
      points: [0.76704, 0.76535]
    },
    // TURN 11 - LEFT TO RIGHT - zakruta sa momentalne nepouziva, ale ak by trebalo, je tu
    {
      type: TriggersTypes.sectorSlightLeftToRightStart,
      points: [0.77533, 0.77402]
    },
    /*
     * {
     *     type: TriggersTypes.sectorLeftToRightStartFullTurn,
     *     points: [0.78383, 0.78243]
     * },
     * {
     *     type: TriggersTypes.sectorLeftToRightEndFullTurn,
     *     points: [0.79097, 0.789195]
     * },
     */
    {
      type: TriggersTypes.sectorSlightLeftToRightEnd,
      points: [0.80098, 0.799073]
    },
    // TURN 12 - RIGHT TO LEFT
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.837875, 0.8352]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.85034, 0.84797]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.925925, 0.9264]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.93737, 0.9378]
    },
    // FINISH
    {
      type: TriggersTypes.afterLastTurn,
      points: [0.9374, 0.9374]
    },
    {
      type: TriggersTypes.beforeFinish,
      points: [0.84, 0.84]
    },
    {
      type: TriggersTypes.finishPhase,
      points: [0.9477, 0.9477]
    }
  ],

  // Typy sekciovych triggerov
  triggersSectionTypes: {
    start: [TriggersTypes.sectorLeftToRightStart, TriggersTypes.sectorRightToLeftStart],
    end: [TriggersTypes.sectorLeftToRightEnd, TriggersTypes.sectorRightToLeftEnd]
  }

}
