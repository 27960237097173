/**
 * Konfig pre debugovatelne veci pre trat
 */
export const hillLineDebugConfig = {

  /** Moznost vytvorenia ciar - plane-y na vytvaranie sektorov */
  createDebugLines: false,

  /** Debug ciary - krivky left&right */
  showDebugLine: false,

  /** Debug sfery - gula, ktorou sa da zistit kde je % na danej krivke */
  showDebugSpheres: false,

  /** Pomocne ciary pre rotaciu */
  arrowHelpers: false,

  /** Vytvorenie ciar pod hracom */
  createLinesUnderPlayer: false,

  /** Zobrazenie idealnej cesty v zakrutach */
  showIdealPathInTurn: {
    showLine: false,
    showArrows: true
  },

  /** ci zobrazime body na ktore ukladame idealne body */
  showIdealPathPoints: false,

  /** Zobrazenie ciar medzi triggerovymi bodmi sektorov */
  debugLinesTriggersSectors: false,

  /** Zobrazenie ciar medzi triggerovymi bodmi */
  debugLinesTriggers: false

}
