/**
 * Konfig pre tutorial
 */
export const tutorialConfig = {
  /** pocet framov po start kliku kedy sa zobrazi anna */
  afterStartDelay: 15,

  /** pocet framov po naraze kedy sa zobrazi anna */
  afterImpactDelay: 15
}
