import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(33.015, 4.0018248558, 75.638748),
      endPosition: new THREE.Vector3(-74.71897, -31.64654, 99.5256958),
      startRotation: new THREE.Vector3(1.9928676, 0.398618739, -1.783663998),
      endRotation: new THREE.Vector3(1.590543954, 0.4973, -1.791926),
      duration: 5,
      notSkippableFrames: 20,
      fov: 81
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(0.6573740616440773, -10.422240018844604, 56.58282680511475),
      endPosition: new THREE.Vector3(-0.7662094160914421, -13.772760152816772, 76.59519786834717),
      startRotation: new THREE.Vector3(1.7238797005001978, -0.0008487091246397267, 3.1361160724219816),
      endRotation: new THREE.Vector3(1.7317312272556276, -0.0004624362647666079, 3.138727822071532),
      duration: 3,
      notSkippableFrames: 20,
      fov: 39
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(1.559538, 0.882866, -3.114546),
        endPosition: new THREE.Vector3(-1.312698, 0.69744, -3.01874),
        startRotation: new THREE.Vector3(1.859, -0.12066, -2.52732),
        endRotation: new THREE.Vector3(1.7875778, 0.1889452, 2.527632),
        duration: 3,
        fov: 65
      },
      {
        startPosition: new THREE.Vector3(0.034789, 0.345311, 18.07483673),
        endPosition: new THREE.Vector3(0.0477, 0.8299, 6.4206171),
        startRotation: new THREE.Vector3(1.67, 0.143, -0.0254955),
        endRotation: new THREE.Vector3(1.690357, -0.06362397654, -0.016855),
        duration: 3,
        fov: 55
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(2.5, 3, -2),
      endPosition: new THREE.Vector3(-2.5, 3, -2),
      duration: 7,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1

}
