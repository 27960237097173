import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { FinishPhaseManager } from '@/app/phases/FinishPhase/FinishPhase'
import { pushbarManager } from '@/app/PushbarManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  AudioNames,
  DisciplinePhases,
  PlayerAnimationsNames
} from '@/app/types'
import {
  audioManager,
  CallbackAnimationTypes,
  modes,
  gsap
} from '@powerplay/core-minigames'
import { player } from '.'
import { lugeAnimationManager } from './LugeAnimationManager'
import { playerMovementManager } from './PlayerMovementManager'

/**
 * manazer animacii pre hraca. Osekany oproti biatlonu lebo zatial nedava zmysel napajat ho na
 * velocitu managera.
 */
export class PlayerAnimationManager {

  /** Counter pre brzdiacu animaciu */
  private brakeLoopCounter = 0

  /** Aktualna vaha pre animaciu vlavo */
  private leftSideWeight = 0

  /** Aktualna vaha pre animaciu vpravo */
  private rightSideWeight = 0

  /** Maximalna vaha pre natocenie */
  private readonly MAX_SIDE_WEIGHT = 1

  /** Krok pri natoceni klasicky */
  private readonly STEP_SIDE_WEIGHT = 0.1

  /** Krok pri natoceni, ked sa sankar nataca na opacnu stranu ako je natoceny */
  private readonly STEP_BACK_SIDE_WEIGHT = 0.2

  /** Krok pri natoceni, ked sa sankar vracia do stredu */
  private readonly STEP_RETURN_SIDE_WEIGHT = 0.05

  /**
   * zapneme animaciu pre start
   */
  public preStartAnimation(): void {

    player.animationsManager.changeToPaused(PlayerAnimationsNames.prestart)
    lugeAnimationManager.preStartAnimation()

  }

  /**
   * zapneme animaciu pre start2
   */
  public preStart2Animation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.prestart2,
      CallbackAnimationTypes.end,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.prestart2,
          CallbackAnimationTypes.end
        )
        console.log('started TRUE')
        pushbarManager.afterPrestart2()

      }
    )

    player.animationsManager.changeTo(PlayerAnimationsNames.prestart2)
    lugeAnimationManager.preStart2Animation()
    pushbarManager.togglePause(true)

  }

  /**
   * Menezovanie prestart2 animacie
   */
  public managePrestart2Animation(): void {

    if (player.animationsManager.getActualAnimation() === PlayerAnimationsNames.prestart2) {

      const percentDone = player.animationsManager.getAnimationPercentageDone(PlayerAnimationsNames.prestart2)
      if (percentDone >= 80) {

        speedManager.setActive(true)

      }

    }

  }

  /**
   * zapneme animaciu pre start2
   */
  public startAnimation(): void {

    player.animationsManager.changeToPaused(PlayerAnimationsNames.start)
    lugeAnimationManager.startAnimation()

  }

  /**
   * zapneme forward animaciu
   */
  public forwardAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.lie,
      CallbackAnimationTypes.loop,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.lie,
          CallbackAnimationTypes.loop
        )
        player.animationsManager.crossfadeTo(PlayerAnimationsNames.forward, 0, true, true)

        if (modes.isTutorial()) return
        playerMovementManager.changeMoveLeftRightActive(true)

      }
    )

    player.animationsManager.crossfadeTo(PlayerAnimationsNames.lie, 0.01, true, false)
    lugeAnimationManager.forwardAnimation()

  }

  /**
   * Zatacanie vlavo
   */
  public turningLeft(): void {

    // rychlejsie dame prec z druhej strany
    if (this.rightSideWeight > 0) this.rightSideWeight -= this.STEP_BACK_SIDE_WEIGHT
    if (this.rightSideWeight < 0) this.rightSideWeight = 0

    this.leftSideWeight += this.STEP_SIDE_WEIGHT
    if (this.leftSideWeight > this.MAX_SIDE_WEIGHT) this.leftSideWeight = this.MAX_SIDE_WEIGHT

  }

  /**
   * Zatacanie vpravo
   */
  public turningRight(): void {

    // rychlejsie dame prec z druhej strany
    if (this.leftSideWeight > 0) this.leftSideWeight -= this.STEP_BACK_SIDE_WEIGHT
    if (this.leftSideWeight < 0) this.leftSideWeight = 0

    this.rightSideWeight += this.STEP_SIDE_WEIGHT
    if (this.rightSideWeight > this.MAX_SIDE_WEIGHT) this.rightSideWeight = this.MAX_SIDE_WEIGHT

  }

  /**
   * Bez zatacania
   */
  public turningStraight(): void {

    this.leftSideWeight -= this.STEP_RETURN_SIDE_WEIGHT
    if (this.leftSideWeight < 0) this.leftSideWeight = 0
    this.rightSideWeight -= this.STEP_RETURN_SIDE_WEIGHT
    if (this.rightSideWeight < 0) this.rightSideWeight = 0

  }

  /**
   * Vyhodnotenie vah po nastaveni zatacania
   */
  public afterEvalTurning(): void {

    player.animationsManager.setWeight(PlayerAnimationsNames.left, this.leftSideWeight)
    player.animationsManager.setWeight(PlayerAnimationsNames.right, this.rightSideWeight)
    const forwardWeight = this.MAX_SIDE_WEIGHT - this.leftSideWeight - this.rightSideWeight
    player.animationsManager.setWeight(PlayerAnimationsNames.forward, forwardWeight)

  }

  /**
   * zapneme end animaciu
   */
  public endAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.end,
      CallbackAnimationTypes.end,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.end,
          CallbackAnimationTypes.end
        )

        player.animationsManager.crossfadeTo(PlayerAnimationsNames.endLoop, 0.01, true, true)
        lugeAnimationManager.endLoopAnimation()

        gsap.to({}, {
          duration: 2,
          onComplete: () => {

            const finishPhase = disciplinePhasesManager
              .getDisciplinePhaseManager(DisciplinePhases.finish) as FinishPhaseManager
            this.emotionAnimation(finishPhase.getEndEmotion())

          }
        })


      }
    )

    // najskor musime nastavit vahy na 0 pre animacie, co sa mohli crossfadeovat
    player.animationsManager.setWeight(PlayerAnimationsNames.left, 0)
    player.animationsManager.setWeight(PlayerAnimationsNames.right, 0)
    player.animationsManager.setWeight(PlayerAnimationsNames.forward, 0)

    player.animationsManager.changeTo(PlayerAnimationsNames.end)
    lugeAnimationManager.endAnimation()

  }

  /**
   * zahrame animaciu emocie
   */
  public emotionAnimation(animation: PlayerAnimationsNames): void {

    // musime vypnut hype a dame konkretny emocny zvuk
    audioManager.stopAudioByName(AudioNames.audienceHype)

    lugeAnimationManager.emotionAnimation()

    if (animation === PlayerAnimationsNames.happy) {

      audioManager.play(AudioNames.audienceYay)
      player.animationsManager.changeTo(PlayerAnimationsNames.happy)
      return

    }

    audioManager.play(AudioNames.audienceSad)
    player.animationsManager.changeTo(PlayerAnimationsNames.neutral)

  }

}

export const playerAnimationManager = new PlayerAnimationManager()
